import Swiper, { EffectFade, Navigation, Pagination, Autoplay } from "swiper";

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);
export default () => {
  const sliderElements = document.querySelectorAll(".js-heroslider__swiper");
  sliderElements.forEach(function (element) {
    const autoplay = element.dataset.autoplay === "true";
    const secondsToWait = parseInt(element.dataset.seconds) || 5;

    let swiperOptions = {
      loop: false,
      speed: 500,
    };

    const paginationOptions = {
      el: ".swiper-pagination",
      clickable: true,
    };

    if (autoplay) {
      swiperOptions = {
        ...swiperOptions,
        autoplay: {
          delay: secondsToWait * 1000,
          stopOnLastSlide: true,
        },
      };
    } else {
      swiperOptions = {
        ...swiperOptions,
        pagination: paginationOptions,
      };
    }

    new Swiper(element, swiperOptions);
  });
};
